<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit"><i></i><span v-text="title"></span></div>
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="125px"
    >
      <el-form-item label="课件模板名称：" prop="title">
        <el-input
          v-model="formData.title"
          placeholder="请输入课件模板名称（不可重名）"
          maxlength="16"
        ></el-input>
      </el-form-item>
      <el-form-item label="画布尺寸：" prop="canvasSize">
        <el-select
          v-model="formData.canvasSize"
          placeholder="请选择画布尺寸"
          style="width: 100%"
        >
          <el-option
            v-for="item in canvasSizeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="ppt页数：" prop="num">
        <el-input v-model="formData.num" placeholder="请输入备注" ></el-input>
      </el-form-item> -->
      <el-form-item label="课件模板：" prop="content">
        <el-upload
          name="file"
          class="upload-demo"
          ref="upload"
          :file-list="fileList"
          drag
          :action="uploadServer"
          :headers="headers"
          :on-success="handleAvatarSuccess"
          :on-exceed="handleExceed"
          :before-remove="beforeRemove"
          :on-remove="handleAvatarRemove"
          :on-change="handleChange"
          :limit="1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            温馨提示：只能上传.json文件
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model="formData.remark"
          placeholder="请输入备注"
          type="textarea"
          :rows="1"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- footer -->
    <el-row slot="footer">
      <el-button type="primary" @click="save" class="dialog-btn"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getToken } from "@/utils/auth";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    editData: {
      default: () => {},
      type: Object,
    },
    title: {
      default: "",
      type: String,
    },
  },
  // 监控表单变化，清除验证
  watch: {
    show() {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields();
        }
      }
    },
  },
  data() {
    return {
      fileList: [],
      headers: { Authorization: getToken() },
      uploadServer: process.env.VUE_APP_API_USERINFO + "common/file/uploadMap",
      rules: {
        title: [
          { required: true, message: "名称不能为空", trigger: "blur" },
          { max: 50, message: "字数限制在50个字内", trigger: "blur" },
        ],
        canvasSize: [
          { required: true, message: "画布尺寸不能为空", trigger: "blur" },
        ],
        content: [{ required: true, message: "文件不能为空", trigger: "blur" }],
      },
      formData: {},
      canvasSizeList: [
        {
          label: "4:3",
          value: "4:3",
        },
        {
          label: "16:9",
          value: "16:9",
        },
      ],
    };
  },
  methods: {
    openDialog() {
      this.formData = {};
      this.fileList = [];
      if (this.title == "新增课件模板") {
      } else {
        this.formData = { ...this.editData };
        this.fileList.push({ url: this.editData.content, name: "课件模板" });
      }
    },
    // 关闭弹窗
    close() {
      this.$emit("close");
    },
    save() {
      console.log(this.formData.content);
      if (!this.formData.content) {
        window.$msg("课件模板不能为空", 2);
        return false;
      }
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.title == "新增课件模板") {
            this.$emit("addForm", this.formData);
          } else {
            this.$emit("editForm", this.formData);
          }
          this.close();
        }
      });
    },
    async handleAvatarSuccess(res) {
      if (res && res.state === "success") {
        this.fileList.push({ url: res.body.fileUrl });
        this.formData.content = res.body.fileUrl;
        window.$msg("上传成功");
      } else {
        window.$msg("上传文件失败", 2);
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除？`);
    },
    handleAvatarRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>