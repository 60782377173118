var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", { domProps: { textContent: _vm._s(_vm.title) } })]
      ),
      _vm.show
        ? _c(
            "el-form",
            {
              ref: "formData",
              attrs: { model: _vm.formData, "label-width": "125px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "预置代码名称：", prop: "title" } },
                [_c("span", [_vm._v(_vm._s(_vm.formData.title))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述：", prop: "description" } },
                [_c("span", [_vm._v(_vm._s(_vm.formData.description))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类：", prop: "category" } },
                [_vm._v(" " + _vm._s(_vm.formData.category) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源：", prop: "source" } },
                [_vm._v(" " + _vm._s(_vm.formData.source) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建人：", prop: "name" } },
                [_vm._v(" " + _vm._s(_vm.formData.name) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间：", prop: "createTime" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formData.createTime
                          ? _vm.formData.createTime.split(".")[0]
                          : "--"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }