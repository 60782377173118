var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", { domProps: { textContent: _vm._s(_vm.title) } })]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "125px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课件模板名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入课件模板名称（不可重名）",
                  maxlength: "16",
                },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "画布尺寸：", prop: "canvasSize" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择画布尺寸" },
                  model: {
                    value: _vm.formData.canvasSize,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "canvasSize", $$v)
                    },
                    expression: "formData.canvasSize",
                  },
                },
                _vm._l(_vm.canvasSizeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课件模板：", prop: "content" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    name: "file",
                    "file-list": _vm.fileList,
                    drag: "",
                    action: _vm.uploadServer,
                    headers: _vm.headers,
                    "on-success": _vm.handleAvatarSuccess,
                    "on-exceed": _vm.handleExceed,
                    "before-remove": _vm.beforeRemove,
                    "on-remove": _vm.handleAvatarRemove,
                    "on-change": _vm.handleChange,
                    limit: 1,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("将文件拖到此处，或"),
                    _c("em", [_vm._v("点击上传")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v(" 温馨提示：只能上传.json文件 ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入备注", type: "textarea", rows: 1 },
                model: {
                  value: _vm.formData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "remark", $$v)
                  },
                  expression: "formData.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }