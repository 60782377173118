<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false">
    <div slot="title" class="dia-tit">
      <i></i><span v-text="title"></span>
    </div>
    <el-form v-if='show' ref="formData" :model="formData" label-width="125px">
      <el-form-item label="预置代码名称：" prop="title">
        <span>{{formData.title}}</span>
      </el-form-item>
      <el-form-item label="描述：" prop="description">
        <span>{{formData.description}}</span>
      </el-form-item>
      <!-- <el-form-item label="类型：" prop="type">
        <span>{{formData.type}}</span>
      </el-form-item> -->
      <el-form-item label="分类：" prop="category">
        {{formData.category}}
      </el-form-item> 
      <el-form-item label="来源：" prop="source">
        {{formData.source}}
      </el-form-item>
      <el-form-item label="创建人：" prop="name">
        {{formData.name}}
      </el-form-item>
      <el-form-item label="创建时间：" prop="createTime">
        {{formData.createTime ? formData.createTime.split('.')[0] :'--'}}
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props:{
    show: {
      default: false,
      type: Boolean
    },
    delData: {
      default: () => {},
      type: Object
    },
    title: {
      default: '',
      type: String
    }
  },
  data(){
    return{
      formData:{},
    }
  },
  methods:{
    openDialog(){
      this.formData = { ...this.delData }
      console.log(this.formData);
    },
    // 关闭弹窗
    close () {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>