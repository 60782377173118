import $axios from '@/utils/request'
/**
 * 查询商品包列表
 * @param {*} data
 * @returns
 */
export function createCourseItem(data) {
    const url = '/openplatform/resource/initResourceWithAll'
    return $axios.fPptPost(url, data)
}

// 获取模板列表
export function $getTemplateList(data) {
    const url = '/openplatform/resource/listTemplate'
    return $axios.fPptPost(url, data)
}
// 模板详情
export function $getTemplateInfo(data) {
    const url = '/openplatform/resource/getTemplateInfo'
    return $axios.fPptGet(url, data)
}

// 新增在线课件模板
export function $addTemplate(data) {
    const url = '/openplatform/resource/addTemplate'
    return $axios.fPptPost(url, data)
}
// 编辑在线课件模板
export function $editTemplate(data) {
    const url = '/openplatform/resource/editTemplate'
    return $axios.fPptPost(url, data)
}

// 编辑模板名称
export function $editTemplateTitle(data) {
    const url = '/openplatform/resource/editTemplateTitle'
    return $axios.fPptPost(url, data)
}
// 删除模板
export function $deleteTemplate(data) {
    const url = '/openplatform/resource/deleteTemplate'
    return $axios.fPptPost(url, data)
}